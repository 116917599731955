body {
  margin: 0;
  padding: 0;
  font-family: "Courier New", Courier, monospace;
  background: linear-gradient(89deg, #FF5EDF 0%, #04C8DE 100%);
}

button {
  cursor: pointer;
}
