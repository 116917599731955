.profile-modal {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close {
  font-size: 3rem;
  font-style: bold;
  cursor: pointer;
}

.modal-close:hover {
  color: grey;
  cursor: pointer;
}
